// Here you can add other styles
@font-face {
  font-family: 'NotoSansRegular';
  src: url(../assets/fonts/NotoSans-Regular.ttf);
}

@font-face {
  font-family: 'NotoSansBold';
  src: url(../assets/fonts/NotoSans-Bold.ttf);
}

@font-face {
  font-family: 'TypoRound';
  src: url(../assets/fonts/Typo_Round_Regular_Demo.otf);
}

body {
  font-family: 'NotoSansRegular';
  background-color: #f7f7f7;
}

.cr-pointer {
  cursor: pointer;
}

.align-items-center {
  align-items: center;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

input[type='checkbox'] {
  visibility: hidden;
}

input[type='checkbox'] + label:before {
  border: 1px solid #979797;
  border-radius: 3px;
  content: '\00a0';
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
input[type='checkbox']:checked + label:before {
  border: 1px solid #fbb12f;
  background: #fbb12f;
  color: #ffffff;
  content: '\2713';
  text-align: center;
}
input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

input[type='checkbox']:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.label-checkbox {
  position: relative;
  padding: 0;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  cursor: pointer;
}

.textarea-not-float,
.textarea-not-float:focus {
  border: none;
  border-bottom: 1px solid #979797;
  width: 50%;
  outline: none;
}

#generate-button {
  border: none;
  color: #ffffff;
  background-color: #fbb12f;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

#new-user-button {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 5px 10px;
  color: #ffffff;
  cursor: pointer;
}

.status-active {
  color: #5f9d2e !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.status-not-active {
  color: #d33232 !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.warning-toaster {
  border-radius: 1.9px;
  background-color: #fffbe7;
  padding: 14px;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #f07a39;
}

.pagination li a {
  padding: 0 10px;
  color: #2f2f2f;
  cursor: pointer;
}

.pagination li.active a {
  color: #20a8d8;
}

.pagination .disabled a {
  color: grey;
}

.pagination .disabled a:hover {
  opacity: 1;
}

.pagination li a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.page-title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3.27px;
  color: #231f20;
  font-family: 'TypoRound';
  text-transform: uppercase;
}

.background-image {
  max-height: 400px;
  height: 50%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.background-image-register {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.card-styles-1 {
  border-radius: 25px;
  background-color: #ffffff;
  padding: 20px 0;
  color: #6c6d71;
}

// .card {
//   border-radius: 25px;
//   box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
// }

.logo-image {
  position: absolute;
  z-index: 1;
  width: 50px;
}

.logo-image-landing {
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 120px;
  left: calc(50% - 50px);
}

.sign-in-landing {
  position: absolute;
  z-index: 1;
  right: 50px;
  top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: #fbb12f;
}

.sign-up-now {
  text-shadow: -3px 2px 1px rgba(49, 49, 49, 0.5);
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 10px;
  text-align: center;
  color: #f7f7f7;
  position: absolute;
  z-index: 1;
  top: 302px;
  width: 50%;
}

.logo-register {
  position: absolute;
  z-index: 1;
  width: 100px;
}

.form-position {
  margin-top: 30px;
}

.form-with-logo {
  margin-top: 100px;
}

.landing-button {
  z-index: 1;
  display: inline-flex;
}

.client-type-button.active {
  background-color: #fbb12f;
  color: #f7f7f7;
}

.form-title {
  font-family: 'NotoSansBold';
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 10px;
  color: #231f20;
  padding: 20px 0;
}

.field-group {
  position: relative;

  input {
    padding: 20px 34px 6px;
    border: none;
    border-bottom: 1px solid #979797;
    outline: 0;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: #2f2f2f;
    border-radius: 0;
  }

  label {
    display: inline-block;
    position: absolute;
    padding: 17px 34px;
    transition: all 150ms ease-in;
    color: #231f20;
    font-size: 12px;
  }
}

.input-icon {
  position: absolute;
  left: 0;
  top: 18px;
  width: 16px;
  height: 16px;
}

/* when the label is activated, it jumps up (using translateY) */
/* you could add animation here as well */
label.field-active {
  transform: translateY(-14px);
  font-size: 11px;
  color: #000;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff,
    0 -2px 0 #fff;
}
.floating-label {
  -webkit-appearance: none !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

input {
  padding: 20px 34px 6px;
  border: none;
  border-bottom: 1px solid #979797;
  outline: 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #2f2f2f;
}

.input-not-float {
  padding: 0;
  width: 50%;
}

.input-not-float::placeholder {
  color: rgba(108, 109, 113, 0.6);
}

.input-not-float:disabled {
  opacity: 0.7;
  color: #5c6873;
}

.form-label {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #6c6d71;
  flex: 0 0 22%;
}

.card-title {
  font-size: 22.8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
}

#button-link-change-password {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.custom-button {
  border-radius: 17.8px;
  background-color: #fbb12f;
  color: #ffffff;
  padding: 10px 20px;
  width: 300px;
  border: none;
  cursor: pointer;
}

.custom-button:disabled {
  opacity: 0.7;
}

.MuiCircularProgress-colorPrimary {
  color: #ffffff !important;
}

.card-description-1 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  font-size: 14px;
}

.card-form {
  border-radius: 25px;
  box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
  background-color: #ffffff;
  z-index: 1;
}

.card-landing {
  padding: 30px 90px;
  margin-top: 70px;
  width: 100%;
  margin-bottom: 70px;
}

.card-header-landing {
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.84px;
  color: #6c6d71;
}

.card-description {
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
}

.card-step {
  border-radius: 27.5px;
  border: solid 2px rgba(190, 190, 190, 0.5);
  padding: 20px 10px;
  min-height: 236px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6c6d71;
}

.card-logo {
  margin-bottom: 60px;
}

.card-registration-button {
  text-align: center;
  padding: 12px 0;
  background-color: #fbb12f;
  color: #ffffff;
  border-radius: 15px;
  margin-top: 30px;
}

footer {
  text-align: center;
  padding: 30px 0;
  background-color: rgba(35, 31, 32, 0.97);
  color: #f7f7f7;
  position: relative;
}

footer li {
  margin-right: 15px;
}

footer ul {
  display: inline-flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

li {
  list-style: none;
}

footer .copyright {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
}

footer .logo-image-footer {
  width: 50px;
  position: absolute;
  right: 50px;
  bottom: 15px;
}

.line {
  border: 1px solid #231f20;
  margin-bottom: 30px;
}

.line-gray {
  border: 1px solid #f7f7f7;
}

.line-2 {
  border: solid 1.4px #231f20;
  margin-bottom: 10px;
}

.field-form {
  padding: 0 30px;
}

#forgot-password {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.btn-submit {
  border-radius: 25px;
  background-color: #fbb12f;
  width: 100%;
  font-family: 'NotoSansBold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 8px 0;
  cursor: pointer;
}

.btn-submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.react-datepicker__input-container input {
  padding: 8px 4px;
  border: 1px solid #979797;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.table td {
  border-top: none !important;
}

.table thead td {
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #6c6d71;
}

.table tbody td {
  font-family: 'NotoSansRegular';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #6c6d71;
  width: 100px;
  height: 48px;
}

td.status-pending {
  color: #6c6d71 !important;
  font-weight: 600;
}

td.status-success {
  color: #5f9d2e !important;
  font-weight: 600;
}

td.status-fail {
  color: #d33232 !important;
  font-weight: 600;
}

.show-limit-title {
  font-family: 'NotoSansRegular';
  font-size: 10.1px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.show-limit {
  padding: 0 8px;
  border-right: 1px solid #979797;
  cursor: pointer;
  font-family: 'NotoSansRegular';
  font-size: 10.1px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.show-limit.active {
  color: #e46161;
}

.show-limit:first-child() {
  padding-left: 0;
}

.show-limit:last-child() {
  border-right: none;
}

.justify-content-space-between {
  justify-content: space-between;
}

.font-bold {
  font-weight: bold;
  color: #191a1f;
}

.table-display {
  color: #d0d0d0;
}

.paginate-button {
  background-image: url('../assets/img/pagination-button.svg');
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border: none;
}

.align-items-center {
  align-items: center;
}

.paginate-button:disabled {
  opacity: 0.5;
}

.paginate-button:first-child {
  transform: rotate(90deg);
}

.paginate-button:last-child {
  transform: rotate(-90deg);
}

.export-file {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 6px 14px;
  color: #ffffff;
  font-size: 12px;
}

.search-button {
  border-radius: 8.4px;
  background-color: #ffde17;
  height: 30px;
  width: 30px;
}

@media only screen and (max-width: 1024px) {
  .container-mobile {
    padding: 0 27px;
  }
  .background-image-register {
    max-height: 400px;
    height: 50%;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .logo-register {
    position: absolute;
    z-index: 1;
    width: 50px;
    left: calc(50% - 16px);
    top: 50px;
  }

  .image-container-register {
    max-height: 400px;
    height: 50%;
    width: 100%;
    position: absolute;
  }

  .image-container {
    position: absolute;
    width: 100%;
  }

  .form-register {
    border-radius: 25px;
    box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
    background-color: #ffffff;
    position: relative;
    top: 150px;
    margin-bottom: 30px;
  }

  .field-form-register {
    padding: 0 30px;
  }

  .background-image-landing-page {
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .client-type-button {
    margin-right: 10px;
    width: 190px;
    background-color: #f7f7f7;
    border-radius: 37.5px;
    text-align: center;
    padding: 8px 0;
    margin-top: 420px;
    color: #6c6d71;
    cursor: pointer;
    font-size: 12px;
  }

  .step-landing {
    z-index: 1;
    padding: 0 20px;
  }

  .sign-up-now {
    font-size: 14px;
  }

  .card-landing {
    padding: 30px 30px;
  }

  .card-step {
    height: 300px;
    margin-bottom: 20px;
  }

  footer .logo-image-footer {
    display: none;
  }

  .sign-in-landing {
    right: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  .image-container-register {
    position: absolute;
    width: 45%;
    min-height: 650px;
    height: 100%;
  }

  .logo-register {
    left: calc(50% - 50px);
    top: calc(50vh - 100px);
  }

  .form-register {
    position: absolute;
    width: 55%;
    left: 45%;
    text-align: center;
    min-height: 650px;
  }

  .field-form-register {
    padding: 0 200px;
  }

  .background-image-landing-page {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .client-type-button {
    margin-right: 10px;
    width: 400px;
    background-color: #f7f7f7;
    border-radius: 37.5px;
    text-align: center;
    padding: 8px 0;
    margin-top: 420px;
    color: #6c6d71;
    cursor: pointer;
  }

  .step-landing {
    z-index: 1;
    padding: 0 180px;
  }

  .card-step {
    margin-bottom: 20px;
  }
}
